import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import App from '../App';
import {Router, Route, Switch} from 'react-router-dom';
import { FramePayProvider } from "@rebilly/framepay-react";
import browserHistory from '../browserHistory';

const settings = {
    injectStyle: true,
    publishableKey: process.env.REACT_APP_REBILLY_PUBLIC_KEY,
};

class Setup extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            store: configureStore()
        };
    }

    render(){
        return(
            <Router history={browserHistory}>
                <Provider store={this.state.store}>
                    <FramePayProvider {...settings}>
                        <Switch>
                            <Route exact path='/' component={App}/>
                        </Switch>
                    </FramePayProvider>
                </Provider>
            </Router>
        )
    }
}

export default Setup;