const env = process.env.REACT_APP_NODE_ENV || 'development';

export default {
    BASE_URL: env === 'production' ? 'https://api-checkout.kephiclothing.com' : 'https://api-kephi.dev.igoluxe.com',
    STORE_URL: 'https://kephiclothing.com/cart',

    CARD_ICONS_PATH: '/payments',
    PAYMENT_LOGOS_PATH: '/logos',
    CHECKOUT_TOKEN: 'checkoutToken',

    METHODS_CONTENT: {
        PAYPAL: {
            methodTitle: 'PayPal Details',
            methodLabel: 'PayPal',
            buttonLabel: 'PayPal'
        },
        BITCOIN: {
            methodTitle: 'CoinPayments Details',
            methodLabel: 'Coin payment',
            buttonLabel: 'Coin payment'
        },
        LITECOIN: {
            methodTitle: 'LiteCoin Details',
            methodLabel: 'LiteCoin payment',
            buttonLabel: 'LiteCoin payment'
        },
        ASTROPAY_CARD: {
            methodTitle: 'AstroPayPayments Details',
            methodLabel: 'AstroPay payment',
            buttonLabel: 'AstroPay payment'
        },
        CASH_DEPOSIT: {
            methodTitle: 'CashDepositPayments Details',
            methodLabel: 'Cash Deposit payment',
            buttonLabel: 'Cash Deposit'
        },
        BANK_TRANSFER: {
            methodTitle: 'BankTransfer Details',
            methodLabel: 'Bank Transfer',
            buttonLabel: 'Bank Transfer'
        },
        E_WALLET: {
            methodTitle: 'E-wallet',
            buttonLabel: 'Log in'
        },
        PAYMENT_CARD: {
            methodTitle: 'Payment card'
        }
    }
};