export default {
    GET_PAYMENT_DATA_ACTION: 'GET_PAYMENT_DATA_ACTION',
    GET_PAYMENT_DATA_REQUEST: 'GET_PAYMENT_DATA_REQUEST',
    GET_PAYMENT_DATA_SUCCESS: 'GET_PAYMENT_DATA_SUCCESS',
    GET_PAYMENT_DATA_ERROR: 'GET_PAYMENT_DATA_ERROR',

    CREATE_TRANSACTION_ACTION: 'CREATE_TRANSACTION_ACTION',
    CREATE_TRANSACTION_REQUEST: 'CREATE_TRANSACTION_REQUEST',
    CREATE_TRANSACTION_SUCCESS: 'CREATE_TRANSACTION_SUCCESS',
    CREATE_TRANSACTION_ERROR: 'CREATE_TRANSACTION_ERROR',

    GET_GATEWAY_ACCOUNTS_ACTION: 'GET_GATEWAY_ACCOUNTS_ACTION',
    GET_GATEWAY_ACCOUNTS_REQUEST: 'GET_GATEWAY_ACCOUNTS_REQUEST',
    GET_GATEWAY_ACCOUNTS_SUCCESS: 'GET_GATEWAY_ACCOUNTS_SUCCESS',
    GET_GATEWAY_ACCOUNTS_ERROR: 'GET_GATEWAY_ACCOUNTS_ERROR',

    HANDLE_TRANSACTION_ACTION: 'HANDLE_TRANSACTION_ACTION',
    HANDLE_TRANSACTION_REQUEST: 'HANDLE_TRANSACTION_REQUEST',
    HANDLE_TRANSACTION_SUCCESS: 'HANDLE_TRANSACTION_SUCCESS',
    HANDLE_TRANSACTION_ERROR: 'HANDLE_TRANSACTION_ERROR',

    CLEAR_TRANSACTION_ERROR: 'CLEAR_TRANSACTION_ERROR',
    HANDLE_TURN_OFF_SPINNER: 'HANDLE_TURN_OFF_SPINNER',

    GET_PUBLIC_KEY_ACTION: 'GET_PUBLIC_KEY_ACTION',
    GET_PUBLIC_KEY_SUCCESS: 'GET_PUBLIC_KEY_SUCCESS',
    GET_PUBLIC_KEY_ERROR: 'GET_PUBLIC_KEY_ERROR',
    CLEAR_PUBLIC_KEY_ACTION: 'CLEAR_PUBLIC_KEY_ACTION'
}