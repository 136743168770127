const createRebillyToken = ({
                                Rebilly, formNode, paymentMethod, handleError, createTransaction,
                                billingAddress = null, params = null, login = null, checkoutToken
                            } ) => {
    try {
        Rebilly.createToken(formNode, { method: paymentMethod, billingAddress
        }).then(token => {
            createTransaction({token, checkoutToken, params, login});
        }).catch(err => {
            handleError(err);
        });
    } catch (err) {
        handleError({message: "No available payment data from cart"})
    }
};

export default createRebillyToken;