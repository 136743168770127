// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationBar_breadcrumb__p3zuZ ul {\n  display: flex; }\n\n.NavigationBar_breadcrumb__p3zuZ li::before {\n  color: #737373;\n  content: \"\"; }\n\n.NavigationBar_breadcrumb__p3zuZ li:first-child::before {\n  content: \"\"; }\n\n.NavigationBar_svgBreadcrumb__27GHE {\n  color: #959494;\n  transform: rotate(0.5turn);\n  width: 10px;\n  margin: 0 0.6428571429em; }\n\n.NavigationBar_breadcrumbItem__3zeip {\n  cursor: pointer;\n  font-size: 12px;\n  color: #737373;\n  font-family: Roboto,Open Sans,Segoe UI,sans-serif; }\n\n.NavigationBar_currentStep__2aKVQ {\n  cursor: auto;\n  color: #4c4c4c !important;\n  font-weight: bold; }\n", "",{"version":3,"sources":["webpack://src/components/NavigationBar/NavigationBar.module.sass"],"names":[],"mappings":"AAAA;EACI,aAAa,EAAA;;AAEjB;EACI,cAAc;EACd,WAAW,EAAA;;AAEf;EACI,WAAW,EAAA;;AAEf;EACI,cAAc;EACd,0BAA0B;EAC1B,WAAW;EACX,wBAAwB,EAAA;;AAE5B;EACI,eAAe;EACf,eAAe;EACf,cAAyB;EACzB,iDAAiD,EAAA;;AAErD;EACI,YAAY;EACZ,yBAAiC;EACjC,iBAAiB,EAAA","sourcesContent":[".breadcrumb ul {\n    display: flex; }\n\n.breadcrumb li::before {\n    color: #737373;\n    content: \"\"; }\n\n.breadcrumb li:first-child::before {\n    content: \"\"; }\n\n.svgBreadcrumb {\n    color: #959494;\n    transform: rotate(0.5turn);\n    width: 10px;\n    margin: 0 0.6428571429em; }\n\n.breadcrumbItem {\n    cursor: pointer;\n    font-size: 12px;\n    color: rgb(115, 115, 115);\n    font-family: Roboto,Open Sans,Segoe UI,sans-serif; }\n\n.currentStep {\n    cursor: auto;\n    color: rgb(76, 76, 76) !important;\n    font-weight: bold; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": "NavigationBar_breadcrumb__p3zuZ",
	"svgBreadcrumb": "NavigationBar_svgBreadcrumb__27GHE",
	"breadcrumbItem": "NavigationBar_breadcrumbItem__3zeip",
	"currentStep": "NavigationBar_currentStep__2aKVQ"
};
export default ___CSS_LOADER_EXPORT___;
