import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Setup from './boot/setup';

ReactDOM.render(<Setup />, document.getElementById('root'));

reportWebVitals();