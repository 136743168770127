import ACTION from '../actions/actionTypes';

const initialState = {
    gatewayAccountsData: null,
    isFetchingGateways: true,
    error: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ACTION.GET_GATEWAY_ACCOUNTS_REQUEST: {
            return {
                ...state,
                isFetchingGateways: true,
                error: null
            }
        }
        case ACTION.GET_GATEWAY_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                gatewayAccountsData: action.gatewayAccountsData,
                isFetchingGateways: false,
                error: null
            };
        }

        case ACTION.GET_GATEWAY_ACCOUNTS_ERROR: {
            return {
                ...state,
                isFetchingGateways: false,
                error: action.error
            }
        }
        default: {
            return state;
        }
    }
}