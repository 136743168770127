import {takeLatest} from 'redux-saga/effects';
import ACTION from '../actions/actionTypes';
import {getCheckoutDataSaga} from './checkoutSaga';
import {createTransactionSaga, handleTransactionSaga} from './transactionSaga';
import {getGatewayAccountsSaga} from './gatewayAccountsSaga';
import {getPublicKeySaga} from "./getPublicKeySaga";

function* rootSaga() {
    yield takeLatest(ACTION.GET_PAYMENT_DATA_ACTION, getCheckoutDataSaga);
    yield takeLatest(ACTION.CREATE_TRANSACTION_ACTION, createTransactionSaga);
    yield takeLatest(ACTION.HANDLE_TRANSACTION_ACTION, handleTransactionSaga);
    yield takeLatest(ACTION.GET_GATEWAY_ACCOUNTS_ACTION, getGatewayAccountsSaga);
    yield takeLatest(ACTION.GET_PUBLIC_KEY_ACTION, getPublicKeySaga);
}

export default rootSaga;