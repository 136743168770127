import ACTION from '../actions/actionTypes';

const initialState = {
    transactionData: null,
    isFetching: false,
    isFullFetching: false,
    status: "ok",
    error: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ACTION.CREATE_TRANSACTION_REQUEST: {
            return {
                ...state,
                isFetching: true,
                status: "ok",
                error: null
            }
        }
        case ACTION.CREATE_TRANSACTION_SUCCESS: {
            return {
                ...state,
                transactionData: action.transactionData,
                isFetching: true,
                status: action.status,
                error: null
            };
        }

        case ACTION.CREATE_TRANSACTION_ERROR: {
            return {
                ...state,
                isFetching: false,
                status: action.status,
                error: action.error
            }
        }

        case ACTION.HANDLE_TRANSACTION_REQUEST: {
            return {
                ...state,
                isFullFetching: true,
                status: "ok",
                error: null
            }
        }

        case ACTION.HANDLE_TRANSACTION_SUCCESS: {
            return {
                ...state,
                transactionData: action.transactionData,
                isFullFetching: true,
                status: action.status,
                error: null
            };
        }

        case ACTION.HANDLE_TRANSACTION_ERROR: {
            return {
                ...state,
                isFetching: false,
                status: action.status,
                error: action.error
            }
        }

        case ACTION.HANDLE_TURN_OFF_SPINNER:{
            return {
                ...state,
                isFetching: false,
                status: "ok",
                error: null
            }
        }

        case ACTION.CLEAR_TRANSACTION_ERROR:{
            return{
                ...state,
                status: "ok",
                transactionData: null,
                error: null
            }
        }

        default: {
            return state;
        }
    }
}