import React, {useEffect, useState} from 'react';
import './App.css';
import './reset.css';
import {
    getGatewayAccountsAction,
    getPaymentDataAction,
    handleTransactionAction
} from "./actions/actionCreator";
import {connect} from 'react-redux';
import CONSTANTS from "./constants/constants";
import alertModal from "./utils/alertModal";
import BillingForm from "./components/BillingForm/BillingForm";
import classNames from "classnames";
import formStyles from "./assets/formsStyles.module.sass";
import BackButton from "./components/BackButton/BackButton";
import {toast, ToastContainer} from "react-toastify";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import isEmail from "validator/lib/isEmail";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import PaymentsBlock from "./components/PaymentsBlock/PaymentsBlock";

const App = (props) => {
    const [viewMode, setViewMode] = useState("billingForm");
    const [billingData, setBillingData] = useState({
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        region: "",
        zip: "",
        email: "",
        phone: ""
    });
    const [inputErrors, setInputErrors] = useState({});
    const [checkoutToken, setCheckoutToken] = useState("");
    const { transactionStore: { isFullFetching }, gatewayAccountsStore, checkoutData } = props;
    const { isFetchingGateways } = gatewayAccountsStore;

    useEffect(() => {
        getPaymentData();
        getGatewayAccounts();
    }, []);

    const getPaymentData = () => {
        const params = new URL(window.location).searchParams;
        const checkoutToken = params.get('token');
        if (!!checkoutToken) {
            window.localStorage.setItem(CONSTANTS.CHECKOUT_TOKEN, checkoutToken);
            setCheckoutToken(checkoutToken);
        } else {
            setCheckoutToken(window.localStorage.getItem(CONSTANTS.CHECKOUT_TOKEN));
        }
        if ( params.get('transaction') && params.get('result') === 'approved' ) {
            const transactionToken = params.get('transaction');
            props.handleTransaction(transactionToken);
        } else if ( params.get('result') === 'declined' ) {
            alertModal('Sorry! But your transaction was declined by payment system.');
            props.getCheckoutData(window.localStorage.getItem(CONSTANTS.CHECKOUT_TOKEN));
        } else {
            props.getCheckoutData(window.localStorage.getItem(CONSTANTS.CHECKOUT_TOKEN));
        }
    };

    const getGatewayAccounts = () => {
        props.getGatewayAccountsList();
    };

    const fillDefaultValues = () => {
        if(!!props.checkoutData?.shipping_address || !!props.checkoutData?.billing_address) {
            const {billing_address, shipping_address} = props.checkoutData;
            setBillingData((prev) => ({
                ...prev,
                firstName: shipping_address?.first_name || billing_address?.first_name,
                lastName: shipping_address?.last_name || billing_address?.last_name,
                address1: shipping_address?.address1 || billing_address?.address1,
                address2: shipping_address?.address2 || billing_address?.address2,
                city: shipping_address?.city || billing_address?.city,
                country: shipping_address?.country_code || billing_address?.country_code,
                region: shipping_address?.province || billing_address?.province,
                zip: shipping_address?.zip || billing_address?.zip,
                email: props.checkoutData?.email,
                phone: shipping_address?.phone
            }));
        }
    };

    useEffect(() => {
        fillDefaultValues();
    },[checkoutData]);

    const handleChange = (key, value) => {
        if(key === 'country') {
            let newErrors = Object.assign({}, inputErrors);
            delete newErrors[key];
            setInputErrors(newErrors);
            billingData.hasOwnProperty(key) && setBillingData({ ...billingData, [key]: value, region: ""});
        } else {
            let newErrors = Object.assign({}, inputErrors);
            delete newErrors[key];
            setInputErrors(newErrors);
            billingData.hasOwnProperty(key) && setBillingData({ ...billingData, [key]: value });
        }
    };

    const setClassNames = (inputName) => {
        return classNames(formStyles.input, {
            [formStyles.inputError]: inputErrors.hasOwnProperty(inputName)
        })
    };

    const backToPreviousStep = () => {
        setViewMode('billingForm');
    };

    const goToNextStep = () => {
        let newErrors = {};
        Object.entries(billingData).forEach(([key, value]) => {
            if (!value && (key !== "address2")) {
                newErrors[`${key}`] = true;
            }
        });
        if (Object.keys(newErrors).length) {
            toast.error('Please, fill all required fields');
        } else {
            if (!isEmail(billingData.email)) {
                newErrors.email = true;
                setInputErrors(newErrors);
                return toast.error('Please enter a valid email')
            } else {
                toast.dismiss();
                setViewMode('paymentForm');
            }
        }
        setInputErrors(newErrors);
    };

    return (
        isFullFetching
            ?
            <div className="globalPagePreloader">
                <div className="loading spinner">&#8230;</div>
            </div>
            :  <div className="App">
                <ToastContainer/>
                <div className="MainContainer">
                    <div className="HeaderContainer">
                        <a className="Logo" href="https://kephiclothing.com/">
                            <span className="LogoHeader">Kephi</span>
                        </a>
                    </div>

                    <NavigationBar viewMode={viewMode} navButtons={{backToPreviousStep, goToNextStep}}/>

                    <SwitchTransition mode={"out-in"}>
                        <CSSTransition key={viewMode}
                                       addEndListener={(node, done) => {
                                           node.addEventListener("transitionend", done, false);
                                       }}
                                       classNames="fade"
                        >
                            <div className="MainContainerWrapper">
                                {
                                    viewMode === 'billingForm'
                                        ?
                                        <div className="SlowTransition">
                                            {
                                                !!checkoutData
                                                    ?
                                                    <>
                                                        <BillingForm checkoutData={checkoutData} billingData={billingData}
                                                                     handleChange={handleChange}
                                                                     setClassNames={setClassNames}/>
                                                        <div className={formStyles.buttonContainer}>
                                                            <BackButton url={checkoutData?.web_url}/>
                                                            <button className={formStyles.button} onClick={goToNextStep}>
                                                                Pay order
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="globalPagePreloader">
                                                        <div className="loading spinner">&#8230;</div>
                                                    </div>
                                            }
                                        </div>
                                        :
                                        <div className="SlowTransition PaymentForm">
                                            <PaymentsBlock isFetching={isFetchingGateways}
                                               gatewayAccounts={gatewayAccountsStore} billingData={billingData}
                                               checkoutData={checkoutData} checkoutToken={checkoutToken}
                                               backToPreviousStep={backToPreviousStep}
                                            />
                                        </div>
                                }
                            </div>
                        </CSSTransition>
                    </SwitchTransition>
                </div>
            </div>
    )
}

const mapStateToProps = (state) => {
    const { checkoutStore: { checkoutData }, gatewayAccountsStore, transactionStore } = state;
    return { checkoutData, gatewayAccountsStore, transactionStore };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCheckoutData: (id) => dispatch(getPaymentDataAction(id)),
        getGatewayAccountsList: () => dispatch(getGatewayAccountsAction()),
        handleTransaction: (transactionId) => dispatch(handleTransactionAction(transactionId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);







// import React, {useEffect, useState} from 'react';
// import './App.css';
// import './reset.css';
// import EwalletForm from "./components/EwalletForm/EwalletForm";
// import {
//     getGatewayAccountsAction,
//     getPaymentDataAction,
//     handleTransactionAction
// } from "./actions/actionCreator";
// import {connect} from 'react-redux';
// import PaymentMethods from "./components/PaymentMethods/PaymentMethods";
// import PaymentCardForm from "./components/PaymentCardForm/PaymentCardForm";
// import CONSTANTS from "./constants/constants";
// import alertModal from "./utils/alertModal";
// import PaymentForm from "./components/PaymentForm/PaymentForm";
// import makeAvailableGateways from './utils/makeAvailableGateways';
// import BillingForm from "./components/BillingForm/BillingForm";
// import classNames from "classnames";
// import formStyles from "./assets/formsStyles.module.sass";
// import BackButton from "./components/BackButton/BackButton";
// import {toast, ToastContainer} from "react-toastify";
// import { SwitchTransition, CSSTransition } from "react-transition-group";
// import isEmail from "validator/lib/isEmail";
// import NavigationBar from "./components/NavigationBar/NavigationBar";
//
// const App = (props) => {
//     const [activeMethod, setActiveMethod] = useState("paypal");
//     const [viewMode, setViewMode] = useState("billingForm");
//     const [billingData, setBillingData] = useState({
//         firstName: "",
//         lastName: "",
//         address1: "",
//         address2: "",
//         city: "",
//         country: "",
//         region: "",
//         zip: "",
//         email: "",
//         phone: ""
//     });
//     const [inputErrors, setInputErrors] = useState({});
//     const [checkoutToken, setCheckoutToken] = useState("");
//     const { transactionStore: { isFullFetching }, gatewayAccountsStore, checkoutData } = props;
//     const { isFetchingGateways } = gatewayAccountsStore;
//
//     useEffect(() => {
//         getPaymentData();
//         getGatewayAccounts();
//     }, []);
//
//     const getPaymentData = () => {
//         const params = new URL(window.location).searchParams;
//         const checkoutToken = params.get('token');
//         if (!!checkoutToken) {
//             window.localStorage.setItem(CONSTANTS.CHECKOUT_TOKEN, checkoutToken);
//             setCheckoutToken(checkoutToken);
//         } else {
//             setCheckoutToken(window.localStorage.getItem(CONSTANTS.CHECKOUT_TOKEN));
//         }
//         if ( params.get('transaction') && params.get('result') === 'approved' ) {
//             const transactionToken = params.get('transaction');
//             props.handleTransaction(transactionToken);
//         } else if ( params.get('result') === 'declined' ) {
//             alertModal('Sorry! But your transaction was declined by payment system.');
//             props.getCheckoutData(window.localStorage.getItem(CONSTANTS.CHECKOUT_TOKEN));
//         } else {
//             props.getCheckoutData(window.localStorage.getItem(CONSTANTS.CHECKOUT_TOKEN));
//         }
//     };
//
//     const getGatewayAccounts = () => {
//         props.getGatewayAccountsList();
//     };
//
//     const setMethod = (method) => {
//         setActiveMethod(method);
//     };
//
//     const renderPaymentForm = () => {
//         const { checkoutData } = props;
//         switch (activeMethod) {
//             case "payment-card":
//                 return <PaymentCardForm checkoutData={checkoutData} paymentMethod={activeMethod} billingData={billingData} checkoutToken={checkoutToken}/>;
//             case "e-wallet":
//                 return <EwalletForm checkoutData={checkoutData} paymentMethod={activeMethod} billingData={billingData} checkoutToken={checkoutToken}/>;
//             default:
//                 return <PaymentForm checkoutData={checkoutData} paymentMethod={activeMethod} billingData={billingData} checkoutToken={checkoutToken}/>;
//         }
//     };
//
//     const fillDefaultValues = () => {
//         if(!!props.checkoutData?.shipping_address || !!props.checkoutData?.billing_address) {
//             const {billing_address, shipping_address} = props.checkoutData;
//             setBillingData((prev) => ({
//                 ...prev,
//                 firstName: shipping_address?.first_name || billing_address?.first_name,
//                 lastName: shipping_address?.last_name || billing_address?.last_name,
//                 address1: shipping_address?.address1 || billing_address?.address1,
//                 address2: shipping_address?.address2 || billing_address?.address2,
//                 city: shipping_address?.city || billing_address?.city,
//                 country: shipping_address?.country_code || billing_address?.country_code,
//                 region: shipping_address?.province || billing_address?.province,
//                 zip: shipping_address?.zip || billing_address?.zip,
//                 email: props.checkoutData?.email,
//                 phone: shipping_address?.phone
//             }));
//         }
//     };
//
//     useEffect(() => {
//         fillDefaultValues();
//     },[checkoutData]);
//
//     // useEffect(() => {
//     //     handleChange('region', "");
//     // },[billingData.country]);
//
//     const handleChange = (key, value) => {
//         if(key === 'country') {
//             let newErrors = Object.assign({}, inputErrors);
//             delete newErrors[key];
//             setInputErrors(newErrors);
//             billingData.hasOwnProperty(key) && setBillingData({ ...billingData, [key]: value, region: ""});
//         } else {
//             let newErrors = Object.assign({}, inputErrors);
//             delete newErrors[key];
//             setInputErrors(newErrors);
//             billingData.hasOwnProperty(key) && setBillingData({ ...billingData, [key]: value });
//         }
//     };
//
//     const setClassNames = (inputName) => {
//         return classNames(formStyles.input, {
//             [formStyles.inputError]: inputErrors.hasOwnProperty(inputName)
//         })
//     };
//
//     const backToPreviousStep = () => {
//         setViewMode('billingForm');
//     };
//
//     const goToNextStep = () => {
//         let newErrors = {};
//         Object.entries(billingData).forEach(([key, value]) => {
//             if (!value && (key !== "address2")) {
//                 newErrors[`${key}`] = true;
//             }
//         });
//         if (Object.keys(newErrors).length) {
//             toast.error('Please, fill all required fields');
//         } else {
//             if (!isEmail(billingData.email)) {
//                 newErrors.email = true;
//                 setInputErrors(newErrors);
//                 return toast.error('Please enter a valid email')
//             } else {
//                 toast.dismiss();
//                 setViewMode('paymentForm');
//             }
//         }
//         setInputErrors(newErrors);
//     };
//
//     const renderPaymentMethods = () => {
//         const {gatewayAccountsData} = props.gatewayAccountsStore;
//         const customerCountryCode = billingData.country
//             || props.checkoutData?.billing_address?.country_code
//             || props.checkoutData?.shipping_address?.country_code;
//         const gatewayArr = makeAvailableGateways(gatewayAccountsData, customerCountryCode);
//
//         return (
//             <PaymentMethods
//                 gatewayAccounts={gatewayArr}
//                 setMethod={setMethod}
//                 activeMethod={activeMethod}
//             />
//         )
//     }
//
//     return (
//         isFullFetching
//             ?
//             <div className="globalPagePreloader">
//                 <div className="loading spinner">&#8230;</div>
//             </div>
//             :  <div className="App">
//                 <ToastContainer/>
//                 <div className="MainContainer">
//                     <div className="HeaderContainer">
//                         <a className="Logo" href="https://kephiclothing.com/">
//                             <span className="LogoHeader">Kephi</span>
//                         </a>
//                     </div>
//
//                     <NavigationBar viewMode={viewMode} navButtons={{backToPreviousStep, goToNextStep}}/>
//
//                     <SwitchTransition mode={"out-in"}>
//                         <CSSTransition key={viewMode}
//                                        addEndListener={(node, done) => {
//                                            node.addEventListener("transitionend", done, false);
//                                        }}
//                                        classNames="fade"
//                         >
//                             <div className="MainContainerWrapper">
//                                 {
//                                     viewMode === 'billingForm'
//                                         ?
//                                         <div className="SlowTransition">
//                                             {
//                                                 !!checkoutData
//                                                     ?
//                                                     <>
//                                                         <BillingForm checkoutData={checkoutData}
//                                                                      billingData={billingData}
//                                                                      handleChange={handleChange}
//                                                                      setClassNames={setClassNames}/>
//                                                         <div className={formStyles.buttonContainer}>
//                                                             <BackButton url={checkoutData?.web_url}/>
//                                                             <button className={formStyles.button}
//                                                                     onClick={goToNextStep}>Pay order
//                                                             </button>
//                                                         </div>
//                                                     </>
//                                                     :
//                                                     <div className="globalPagePreloader">
//                                                         <div className="loading spinner">&#8230;</div>
//                                                     </div>
//                                             }
//                                         </div>
//                                         :
//                                         <div className="SlowTransition PaymentForm">
//
//                                             <div className="PaymentContainerWrapper">
//                                                 <div className="MainPaymentContainer">
//                                                     {
//                                                         isFetchingGateways
//                                                             ?
//                                                             <div className="loadingContainer">
//                                                                 <div className="previousStepLinkLabel">Loading available payment
//                                                                     methods ...
//                                                                 </div>
//                                                                 <div className="gatewaysLoading loading">&#8230;</div>
//                                                             </div>
//                                                             :
//                                                             <>
//                                                                 {
//                                                                     gatewayAccountsStore?.gatewayAccountsData?.length
//                                                                         ?
//                                                                         <>
//                                                                             <div className="PaymentMethodsContainer">
//                                                                                 {renderPaymentMethods()}
//                                                                             </div>
//                                                                             {
//                                                                                 checkoutData
//                                                                                     ?
//                                                                                     <div className="PaymentFormContainer">
//                                                                                         {renderPaymentForm()}
//                                                                                     </div>
//                                                                                     :
//                                                                                     <div>
//                                                                                         <h1 className="noDataLabel">No available
//                                                                                             payments data</h1>
//                                                                                     </div>
//                                                                             }
//                                                                         </>
//                                                                         :
//                                                                         <div>
//                                                                             <h1 className="noDataLabel">No available payments
//                                                                                 methods right now</h1>
//                                                                         </div>
//                                                                 }
//                                                             </>
//                                                     }
//                                                 </div>
//
//                                                 <div className={formStyles.buttonContainer}>
//                                                     <a className="previousStepLink" onClick={backToPreviousStep}>
//                                                         <svg focusable="false" aria-hidden="true"
//                                                              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" width="14px">
//                                                             <path d="M8 1L7 0 3 4 2 5l1 1 4 4 1-1-4-4"/>
//                                                         </svg>
//                                                         <span className="previousStepLinkLabel">Return to information</span>
//                                                     </a>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                 }
//                             </div>
//                         </CSSTransition>
//                     </SwitchTransition>
//                 </div>
//             </div>
//     )
// }
//
// const mapStateToProps = (state) => {
//     const { checkoutStore: { checkoutData }, gatewayAccountsStore, transactionStore } = state;
//     return { checkoutData, gatewayAccountsStore, transactionStore };
// };
//
// const mapDispatchToProps = (dispatch) => {
//     return {
//         getCheckoutData: (id) => dispatch(getPaymentDataAction(id)),
//         getGatewayAccountsList: () => dispatch(getGatewayAccountsAction()),
//         handleTransaction: (transactionId) => dispatch(handleTransactionAction(transactionId))
//     }
// };
//
// export default connect(mapStateToProps, mapDispatchToProps)(App);
