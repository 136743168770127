import COUNTRIES from '../constants/countryForMethods';

const checkCountry = (gatewayName, countryCode) => {
    const availableCountries = COUNTRIES[`${gatewayName}`];

    if (availableCountries) {
        return availableCountries.includes(`${countryCode}`)
    } else {
        return true;
    }
}

export default checkCountry;