import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {clearTransactionError, createTransactionAction, getPublicKeyAction, clearPublicKeyStore} from "../../actions/actionCreator";
import styles from './EwalletForm.module.sass';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from "classnames";
import formStyles from "../../assets/formsStyles.module.sass";
import {withFramePayCardComponent} from "@rebilly/framepay-react";
import createRebillyToken from "../../utils/createRebillyToken";
import FormHeader from "../FormHeader/FormHeader";
import CONSTANTS from "../../constants/constants";
import * as JsEncryptModule from 'jsencrypt';

const EwalletForm = (props) => {
    const { transactionStore, paymentMethod, Rebilly, createTransaction, checkoutToken,
        clearTransactionError, getPublicKey, publicKeyStore, clearPublicKeyStore, billingData } = props;
    const { isFetching } = transactionStore;
    const { publicKey, fileName } = publicKeyStore;
    const [spinnerActive, setSpinnerActive] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorPayment, setErrorPayment] = useState(false);
    const [credentials, setCredentials] = useState({login: "", password: ""});

    const validSelectedMethod = paymentMethod.replace(/([\s-]+)/, '_').toUpperCase();
    const content = CONSTANTS.METHODS_CONTENT[`${validSelectedMethod}`];
    let formNode = null;

    const handleChange = (key, value) => {
        setErrorPayment(false);
        let newErrors = Object.assign({}, errors);
        delete newErrors[key];
        setErrors(newErrors);
        credentials.hasOwnProperty(key) && setCredentials({ ...credentials, [key]: value });
    };

    const handleError = (err) => {
        toast.error(err.message);
    };

    const onClickedHandler = (values) => {
        setSpinnerActive(true);
        values.preventDefault();
        let newErrors = {};
        Object.entries(credentials).forEach(([key, value]) => {
            if (!value) {
                newErrors[`${key}`] = true;
            }
        });

        if (Object.keys(newErrors).length) {
            setSpinnerActive(false);
            toast.error('Please, fill all required fields');
        } else {
            toast.dismiss();
            getPublicKey();
        }
        setErrors(newErrors);
    };

    const setErrorFunc = () => {
        setErrorPayment(true);
        setSpinnerActive(false);
    };

    useEffect(() => {
        if( !['ok', 'transactionSuccess'].includes(transactionStore?.status) ) {
            setErrorFunc();
        }
    }, [transactionStore]);

    useEffect(() => {
        return () => {
            setSpinnerActive(false);
            clearTransactionError();
            clearPublicKeyStore();
            setErrors({});
        };
    }, []);

    useEffect( () => {
        if (!!publicKey) {
            const jsEncrypt = new JsEncryptModule.JSEncrypt();
            jsEncrypt.setPublicKey(publicKey);
            const { firstName, lastName, address1, address2, city, country, region, zip, email, phone } = billingData;
            const pass = jsEncrypt.encrypt(credentials.password);

            clearPublicKeyStore();
            createRebillyToken({
                Rebilly,
                formNode,
                paymentMethod,
                checkoutToken,
                handleError,
                createTransaction,
                billingAddress: {
                    firstName,
                    lastName,
                    address: address1,
                    address2: address2 || null,
                    city,
                    region,
                    country,
                    postalCode: zip,
                    emails: [{
                        label: 'main',
                        value: email,
                        primary: true
                    }],
                    phoneNumbers: [{
                        label: 'main',
                        value: phone,
                        primary: true
                    }]
                },
                params: {
                    login: credentials.login,
                        pass,
                        fileName
                }
            });
        }
    }, [publicKeyStore?.publicKey])

    const setClassNames = (inputName) => {
        return classNames(formStyles.input, {
                [formStyles.inputError]: errors.hasOwnProperty(inputName)
            })
    };

    return (
        <div className={formStyles.PaymentFormMainContainer}>
            <FormHeader isFetching={/*isFetching && !Object.keys(errors).length && */spinnerActive} methodTitle={content.methodTitle}/>

            <form onSubmit={onClickedHandler} ref={node => (formNode = node)} noValidate>
                <fieldset className={formStyles.fieldsetContainer}>
                    <div className={styles.field}>
                        <input
                            className={setClassNames('login')}
                            type="text"
                            name="login"
                            placeholder="Login"
                            value={credentials.login}
                            onChange={(e) => handleChange('login', e.target.value)}
                        />
                        <label htmlFor="login" className={formStyles.inputLabel}>Login</label>
                    </div>
                    <div className={styles.field}>
                        <input
                            className={setClassNames('password')}
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={credentials.password}
                            onChange={(e) => handleChange('password', e.target.value)}
                        />
                        <label htmlFor="password" className={formStyles.inputLabel}>Password</label>
                    </div>
                    {
                        (errorPayment) &&
                        <div className={formStyles.fieldError}>
                            {transactionStore?.transactionData?.message}
                        </div>
                    }
                </fieldset>

                <div>
                    <button className={formStyles.button}>{content.buttonLabel}</button>
                </div>
            </form>
        </div>
    );
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch) => (
    {
        createTransaction: (data) => dispatch(createTransactionAction(data)),
        clearTransactionError: () => dispatch(clearTransactionError()),
        clearPublicKeyStore: () => dispatch(clearPublicKeyStore()),
        getPublicKey: () => dispatch(getPublicKeyAction())
    }
);

export default connect(mapStateToProps, mapDispatchToProps)(withFramePayCardComponent(EwalletForm));