import ACTION from './actionTypes';

export const getPaymentDataAction = (data) => {
    return {
        type: ACTION.GET_PAYMENT_DATA_ACTION,
        data: data
    }
};

export const createTransactionAction = (data) => {
    return {
        type: ACTION.CREATE_TRANSACTION_ACTION,
        data: data
    }
};

export const handleTransactionAction = (transactionId) => {
    return {
        type: ACTION.HANDLE_TRANSACTION_ACTION,
        data: transactionId
    }
}

export const getGatewayAccountsAction = () => {
    return {
        type: ACTION.GET_GATEWAY_ACCOUNTS_ACTION
    }
};

export const clearTransactionError = () => {
    return {
        type: ACTION.CLEAR_TRANSACTION_ERROR
    }
};

export const turnOffSpinnerAction = () => {
    return {
        type: ACTION.HANDLE_TURN_OFF_SPINNER
    }
};

export const getPublicKeyAction = () => {
    return {
        type: ACTION.GET_PUBLIC_KEY_ACTION
    }
}

export const clearPublicKeyStore = () => {
    return {
        type: ACTION.CLEAR_PUBLIC_KEY_ACTION
    }
};