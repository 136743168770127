import {put} from 'redux-saga/effects';
import ACTION from '../actions/actionTypes';
import * as restController from '../api/rest/restController';

export function* getGatewayAccountsSaga() {
    try {
        yield put({type: ACTION.GET_GATEWAY_ACCOUNTS_REQUEST});
        const { data } = yield restController.getGatewayAccounts();
        yield put({type: ACTION.GET_GATEWAY_ACCOUNTS_SUCCESS, gatewayAccountsData: data});
    }
    catch (e) {
        yield put({type: ACTION.GET_GATEWAY_ACCOUNTS_ERROR, error: e.response});
    }
}