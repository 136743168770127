import React from 'react';
import styles from './PaymentMethods.module.sass';
import CONSTANTS from "../../constants/constants";

const PaymentMethods = ({gatewayAccounts, setMethod, activeMethod}) => {
    const setActiveMethod = (method) => {
        setMethod(method);
    };

    const renderMethods = () => {
        const methodsArr = [];
        if (gatewayAccounts) {
            gatewayAccounts.forEach( (account, key) => {
                if (account !== null) {
                    const validCardName = account.replace(/([\s-]+)/, '_').toUpperCase();
                    methodsArr.push(
                        <li key={key} className={styles.methodContainer}>
                            <input
                                type="radio"
                                name="active-method"
                                id={`payment-method-${key}`}
                                checked={account === activeMethod}
                                onChange={ () => {
                                    setActiveMethod(account)
                                }}
                                style={{"cursor": "pointer"}}
                            />
                            <label className={styles.methodContent} htmlFor={`payment-method-${key}`}>
                                <div className={styles.contentWrapper}>
                                    <div className={styles.imgContainer}>
                                        <img className={styles.methodImage} src={`${CONSTANTS.PAYMENT_LOGOS_PATH}/${validCardName}.png`} alt={"img"}/>
                                    </div>
                                    <label className={styles.methodLabel} htmlFor={`payment-method-${key}`}>{key}</label>
                                </div>
                            </label>
                        </li>
                    )
                }
            });
            return methodsArr;
        }
    }

    return (
        <div className={styles.paymentMethodsMainContainer}>
            <h1 className={styles.paymentMethodsHeader}>Payment methods</h1>
            <div className={styles.allMethodsContainer}>
                {renderMethods()}
            </div>
        </div>
    );
}

export default PaymentMethods;