import ACTION from '../actions/actionTypes';

const initialState = {
    publicKey: null,
    fileName: null,
    isKeyFetching: false,
    error: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ACTION.GET_PUBLIC_KEY_SUCCESS: {
            return {
                ...state,
                publicKey: action.publicKey,
                fileName: action.fileName,
                isKeyFetching: true,
                error: null
            };
        }

        case ACTION.GET_PUBLIC_KEY_ERROR: {
            return {
                ...state,
                isKeyFetching: false,
                error: action.error
            }
        }

        case ACTION.CLEAR_PUBLIC_KEY_ACTION:{
            return{
                ...state,
                publicKey: null,
                fileName: null,
                isKeyFetching: false,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}