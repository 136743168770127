import {put} from 'redux-saga/effects';
import ACTION from '../actions/actionTypes';
import * as restController from '../api/rest/restController';

export  function* getCheckoutDataSaga(action) {
    try {
        yield put({type: ACTION.GET_PAYMENT_DATA_REQUEST});
        const { data } = yield restController.getPaymentData(action);
        yield put({type: ACTION.GET_PAYMENT_DATA_SUCCESS, checkoutData: data.checkout});
    }
    catch (e) {
        yield put({type: ACTION.GET_PAYMENT_DATA_ERROR, error: e.response});
    }
}