import React, {useEffect, useState} from 'react';
import styles from './PaymentCardForm.module.sass';
import formStyles from '../../assets/formsStyles.module.sass';
import { withFramePayCardComponent } from "@rebilly/framepay-react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {clearTransactionError, createTransactionAction} from "../../actions/actionCreator";
import {connect} from "react-redux";
import createRebillyToken from "../../utils/createRebillyToken";
import FormHeader from "../FormHeader/FormHeader";
import CONSTANTS from "../../constants/constants";

const PaymentCardForm = (props) => {
    const { transactionStore, paymentMethod, checkoutData, createTransaction, Rebilly,
        CardNumberElement, CardCvvElement, CardExpiryElement, billingData, checkoutToken } = props;
    const { isFetching } = transactionStore;

    const [data, setData] = useState(null);
    const [errorPayment, setErrorPayment] = useState(false);
    const validSelectedMethod = paymentMethod.replace(/([\s-]+)/, '_').toUpperCase();
    const content = CONSTANTS.METHODS_CONTENT[`${validSelectedMethod}`];

    let formNode = null;
    let totalPrice = '';
    let currency = '';
    if (checkoutData !== null ) {
        totalPrice = checkoutData.total_price;
        currency = checkoutData.currency;
    }

    const handleError = (err) => {
        err.code === 'api-validation'
            ? toast.error('Please fill in card\'s field.')
            : toast.error(err)
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setErrorPayment(false);

        try {
            const { firstName, lastName, address1, address2, city, country, region, zip, email, phone } = billingData;
            createRebillyToken({
                Rebilly,
                formNode,
                paymentMethod,
                checkoutToken,
                handleError,
                createTransaction,
                billingAddress: {
                    firstName,
                    lastName,
                    address: address1,
                    address2: address2 || null,
                    city,
                    region,
                    country,
                    postalCode: zip,
                    emails: [{
                        label: 'main',
                        value: email,
                        primary: true
                    }],
                    phoneNumbers: [{
                        label: 'main',
                        value: phone,
                        primary: true
                    }],
                    data
                }
            });
        } catch (err) {
            handleError(err);
        }
    };

    const onChangeHandler = (dataValues) => {
        setData( dataValues.valid ? null : dataValues );
    };

    const getErrorMessage = () => {
        return (JSON.stringify(data.error.message)).replace(/"/g,'')
    };

    const setErrorFunc = () => {
        setErrorPayment(true)
    };

    useEffect(() => {
        if( !['ok', 'waiting-approval'].includes(transactionStore?.status) ) {
            setErrorFunc();
        }
    }, [transactionStore]);

    useEffect(() => {
        return () => {
            props.clearTransactionError();
            setErrorPayment(false);
        };
    }, [paymentMethod]);

    return (
        <div className={formStyles.PaymentFormMainContainer}>
            {
                !!checkoutData
                    ?
                    <>
                        <FormHeader isFetching={isFetching} methodTitle={content.methodTitle}/>
                        <form ref={node => (formNode = node)} onSubmit={onSubmit}>
                            <fieldset className={formStyles.fieldsetContainer}>
                                <div className={formStyles.field}>
                                    <CardNumberElement className={styles.cardElement}
                                                       onChange={ dataValues => onChangeHandler(dataValues) }/>
                                </div>
                                <div className={formStyles.field}>
                                    <CardExpiryElement className={styles.cardElement}
                                                       onChange={ dataValues => onChangeHandler(dataValues) }/>
                                </div>
                                <div className={formStyles.field}>
                                    <CardCvvElement className={styles.cardElement}
                                                    onChange={ dataValues => onChangeHandler(dataValues) }/>
                                </div>

                                <div className={formStyles.field} id="mounting-point"/>

                                { data &&
                                    <span className={styles.warning}>
                                        { getErrorMessage(data) } <br/> Please check your data.
                                    </span>
                                }
                                {
                                    (errorPayment) &&
                                    <div className={formStyles.fieldError}>
                                        {transactionStore?.transactionData?.message}
                                    </div>
                                }
                            </fieldset>
                            <hr/>
                            <div className={styles.buttonContainer}>
                                <button className={formStyles.button}>{`Pay ${totalPrice} ${currency}`}</button>
                            </div>
                        </form>
                    </>
                    :
                    <div className={styles.previousStepLink}>
                        <h1 className={styles.previousStepLinkLabel}>
                            No available customer data.
                        </h1>
                    </div>
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch) => {
    return {
        createTransaction: (data) => dispatch(createTransactionAction(data)),
        clearTransactionError: () => dispatch(clearTransactionError())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withFramePayCardComponent(PaymentCardForm));