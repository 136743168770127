import {put} from 'redux-saga/effects';
import ACTION from '../actions/actionTypes';
import * as restController from '../api/rest/restController';

export function* getPublicKeySaga() {
    try {
        const { data } = yield restController.getPublicKey();
        yield put({type: ACTION.GET_PUBLIC_KEY_SUCCESS, publicKey: data.publicKey, fileName: data.fileName});
    }
    catch (e) {
        yield put({type: ACTION.GET_PUBLIC_KEY_ERROR, error: e.response});
    }
}