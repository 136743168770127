import React, {useState} from 'react';
import formStyles from "../../assets/formsStyles.module.sass";
import styles from "./PaymentsBlock.module.sass";
import makeAvailableGateways from "../../utils/makeAvailableGateways";
import PaymentMethods from "../PaymentMethods/PaymentMethods";
import PaymentCardForm from "../PaymentCardForm/PaymentCardForm";
import EwalletForm from "../EwalletForm/EwalletForm";
import PaymentForm from "../PaymentForm/PaymentForm";

const PaymentsBlock = (props) => {
    const { isFetching, gatewayAccounts, billingData, checkoutData, checkoutToken, backToPreviousStep } = props;
    const [activeMethod, setActiveMethod] = useState("paypal");

    const renderPaymentForm = () => {
        const { checkoutData } = props;
        switch (activeMethod) {
            case "payment-card":
                return <PaymentCardForm checkoutData={checkoutData} paymentMethod={activeMethod} billingData={billingData} checkoutToken={checkoutToken}/>;
            case "e-wallet":
                return <EwalletForm checkoutData={checkoutData} paymentMethod={activeMethod} billingData={billingData} checkoutToken={checkoutToken}/>;
            default:
                return <PaymentForm checkoutData={checkoutData} paymentMethod={activeMethod} billingData={billingData} checkoutToken={checkoutToken}/>;
        }
    };

    const setMethod = (method) => {
        setActiveMethod(method);
    };

    const renderPaymentMethods = () => {
        const customerCountryCode = billingData.country
            || checkoutData?.billing_address?.country_code
            || checkoutData?.shipping_address?.country_code;
        const gatewayArr = makeAvailableGateways(gatewayAccounts.gatewayAccountsData, customerCountryCode);

        return (
            <PaymentMethods
                gatewayAccounts={gatewayArr}
                setMethod={setMethod}
                activeMethod={activeMethod}
            />
        )
    };

    return (
        <div className={styles.PaymentContainerWrapper}>
            <div className={styles.MainPaymentContainer}>
                {
                    isFetching
                        ?
                        <div className={styles.loadingContainer}>
                            <div className={styles.previousStepLinkLabel}>Loading available payment methods ...</div>
                            <div className={styles.gatewaysLoading + ' ' + styles.loading}>&#8230;</div>
                        </div>
                        :
                        <>
                            {
                                gatewayAccounts?.gatewayAccountsData?.length
                                    ?
                                    <>
                                        <div className={styles.PaymentMethodsContainer}>
                                            {renderPaymentMethods()}
                                        </div>
                                        {
                                            checkoutData
                                                ?
                                                <div className={styles.PaymentFormContainer}>
                                                    {renderPaymentForm()}
                                                </div>
                                                :
                                                <div>
                                                    <h1 className={styles.noDataLabel}>No available
                                                        payments data</h1>
                                                </div>
                                        }
                                    </>
                                    :
                                    <div>
                                        <h1 className={styles.noDataLabel}>No available payments
                                            methods right now</h1>
                                    </div>
                            }
                        </>
                }
            </div>

            <div className={formStyles.buttonContainer}>
                <span className={styles.previousStepLink} onClick={backToPreviousStep}>
                    <svg focusable="false" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" width="14px">
                        <path d="M8 1L7 0 3 4 2 5l1 1 4 4 1-1-4-4"/>
                    </svg>
                    <span className={styles.previousStepLinkLabel}>Return to information</span>
                </span>
            </div>
        </div>
    );
};

export default PaymentsBlock;