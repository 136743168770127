import {put} from 'redux-saga/effects';
import ACTION from '../actions/actionTypes';
import * as restController from '../api/rest/restController';

export function* createTransactionSaga(action) {
    yield put({type: ACTION.CREATE_TRANSACTION_REQUEST});
    try {
        const { data } = yield restController.createTransaction(action.data);
        yield put({type: ACTION.CREATE_TRANSACTION_SUCCESS, transactionData: data, status: data.status});
        if (data.status === 'transactionSuccess' || data.status === 'waiting-approval') {
            window.location.replace(`${data.url}`);
        } else {
            yield put({type: ACTION.HANDLE_TURN_OFF_SPINNER});
        }
    }
    catch (e) {
        yield put({type: ACTION.CREATE_TRANSACTION_ERROR, error: e.response});
    }
}

export function* handleTransactionSaga(action) {
    yield put({type: ACTION.HANDLE_TRANSACTION_REQUEST});
    try {
        const { data } = yield restController.handleTransaction(action);
        yield put({type: ACTION.HANDLE_TRANSACTION_SUCCESS, transactionData: data, status: data.status});

        if (data.status === 'transactionSuccess') {
            window.location.replace(`${data.url}`);
        } else {
            yield put({type: ACTION.HANDLE_TURN_OFF_SPINNER});
        }
    }
    catch (e) {
        yield put({type: ACTION.HANDLE_TRANSACTION_ERROR, error: e.response});
    }
}