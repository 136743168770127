import {confirmAlert} from 'react-confirm-alert';
import '../react-confirm-alert.css';

const alertModal = (message) => {

    confirmAlert({
        message: `${message} You can choose another payment method.`,
        buttons: [
            {
                label: 'OK'
            }
        ]
    });
};

export default alertModal;



