import React from 'react';
import classNames from "classnames";
import formStyles from "../../assets/formsStyles.module.sass";
import FormSectionHeader from "../FormSectionHeader/FormSectionHeader";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import BillingInputItem from "../BillingInputItem/BillingInputItem";
import 'react-toastify/dist/ReactToastify.css';
import {connect} from "react-redux";

const BillingForm = (props) => {
    const { billingData, handleChange, setClassNames } = props;
    const { firstName, lastName, address1, address2, city, country, region, zip, email, phone } = billingData;

    const inputClassNameField = classNames(formStyles.fieldsetContainer, formStyles.customerFieldsetContainer );
    const setCountryFieldClasses = (inputName) => {
        return classNames(setClassNames(inputName), formStyles.countryField)
    };

    return (
        <div className={formStyles.customerDataContainer}>
            <FormSectionHeader title={"Billing information"}/>

            <fieldset className={inputClassNameField}>
                <BillingInputItem name={"firstName"} placeholder={"First name"} handleChange={handleChange} value={firstName} setClassNames={setClassNames}/>
                <BillingInputItem name={"lastName"} placeholder={"Last name"} handleChange={handleChange} value={lastName} setClassNames={setClassNames}/>
            </fieldset>

            <fieldset className={inputClassNameField}>
                <BillingInputItem name={"address1"} placeholder={"Address"} handleChange={handleChange} value={address1} setClassNames={setClassNames}/>
            </fieldset>

            <fieldset className={inputClassNameField}>
                <BillingInputItem name={"address2"} placeholder={"Apartment, suite, etc. (optional)"} handleChange={handleChange} value={address2} setClassNames={setClassNames}/>
            </fieldset>

            <fieldset className={inputClassNameField}>
                <BillingInputItem name={"city"} placeholder={"City"} handleChange={handleChange} value={city} setClassNames={setClassNames}/>
            </fieldset>

            <fieldset className={inputClassNameField}>
                <div className={formStyles.customerField}>
                    <CountryDropdown name={"country"} placeholder="Country/Region" value={country}
                                     valueType={"short"}
                                     onChange={(value) => handleChange('country', value)}
                                     classes={setCountryFieldClasses("country")}/>
                    <label htmlFor={"country"} className={formStyles.inputLabel}>Country/Region</label>
                </div>
                <div className={formStyles.customerField}>
                    <RegionDropdown name={"region"} country={country} value={region}
                                    countryValueType={"short"}
                                    onChange={(value) => handleChange('region', value)}
                                    classes={setCountryFieldClasses("region")} blankOptionLabel={"Region"}/>
                    <label htmlFor={"region"} className={formStyles.inputLabel}>Region</label>
                </div>
                <BillingInputItem name={"zip"} placeholder={"Postal code"} handleChange={handleChange} value={zip} setClassNames={setClassNames}/>
            </fieldset>

            <fieldset className={formStyles.fieldsetContainer}>
                <BillingInputItem name={"email"} placeholder={"Email"} handleChange={handleChange} value={email} setClassNames={setClassNames}/>
            </fieldset>

            <fieldset className={formStyles.fieldsetContainer}>
                <BillingInputItem name={"phone"} placeholder={"Phone"} handleChange={handleChange} value={phone} setClassNames={setClassNames}/>
            </fieldset>

        </div>
    );
};

export default connect(null, null)(BillingForm);