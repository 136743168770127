import React from 'react';
import formStyles from "../../assets/formsStyles.module.sass";

const FormSectionHeader = ({title}) => {
    return (
        <div className={formStyles.methodDescriptionContainer}>
            <h2 className={formStyles.sectionTitle}>
                {title}
            </h2>
        </div>
    );
};

export default FormSectionHeader;