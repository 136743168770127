import React from 'react';
import CONSTANTS from "../../constants/constants";
import styles from "../PaymentsBlock/PaymentsBlock.module.sass";

const BackButton = ({url}) => {
    return (
        <a className={styles.previousStepLink} href={url || CONSTANTS.STORE_URL}>
            <svg focusable="false" aria-hidden="true"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" width="14px">
                <path d="M8 1L7 0 3 4 2 5l1 1 4 4 1-1-4-4"/>
            </svg>
            <span className={styles.previousStepLinkLabel}>Return to checkout</span>
        </a>
    );
};

export default BackButton;