import ACTION from '../actions/actionTypes';

const initialState = {
    checkoutData: null,
    isFetching: true,
    error: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ACTION.GET_PAYMENT_DATA_REQUEST: {
            return {
                ...state,
                isFetching: true,
                error: null
            }
        }
        case ACTION.GET_PAYMENT_DATA_SUCCESS: {
            return {
                ...state,
                checkoutData: action.checkoutData,
                isFetching: false,
                error: null
            };
        }

        case ACTION.GET_PAYMENT_DATA_ERROR: {
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        }
        default: {
            return state;
        }
    }
}