const GATEWAY_ARRAY = new Map([
    ["Card Payment", null],
    ["PayPal", null],
    ["CoinPayment", null],
    ["AstroPay Cards", null],
    ["Cash Deposit", null],
    ["Bank Transfer", null],
    ["E-wallet", "e-wallet"]
]);

export default GATEWAY_ARRAY;