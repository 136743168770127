import React from 'react';
import formStyles from "../../assets/formsStyles.module.sass";

const BillingInputItem = ({name, placeholder, handleChange, value, setClassNames}) => {

    return (
        <div className={formStyles.customerField}>
            <input
                type="text"
                name={name}
                placeholder={placeholder}
                value={value}
                className={setClassNames(name)}
                onChange={(e) => handleChange(name, e.target.value)}
            />
            <label htmlFor={name} className={formStyles.inputLabel}>{placeholder}</label>
        </div>
    );
};

export default BillingInputItem;