import React from 'react';
import formStyles from "../../assets/formsStyles.module.sass";

const FormHeader = ({isFetching, methodTitle}) => {
    return (
        <div>
            {
                isFetching &&
                <div className="pagePreloader">
                    <div className="loading spinner">&#8230;</div>
                </div>
            }
            <div className={formStyles.methodDescriptionContainer}>
                <h2 className={formStyles.methodTitle}>
                    {methodTitle}
                </h2>
            </div>
        </div>
    );
};

export default FormHeader;