// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  font-family: Roboto, Open Sans, Segoe UI, sans-serif; }\n\n.EwalletForm_PaymentFormMainContainer__taNMC {\n  background-color: white;\n  padding: 2em 2em;\n  width: 100%; }\n\n.EwalletForm_field__2RZmr {\n  margin-top: 10px;\n  margin-bottom: 10px;\n  position: relative; }\n\nlabel {\n  display: block;\n  margin-bottom: 8px; }\n\n.EwalletForm_cardElement__3E-Ks {\n  margin-top: 10px; }\n", "",{"version":3,"sources":["webpack://src/components/EwalletForm/EwalletForm.module.sass"],"names":[],"mappings":"AAAA;EACE,oDAAoD,EAAA;;AAEtD;EACE,uBAAuB;EACvB,gBAAgB;EAChB,WAAW,EAAA;;AAEb;EACE,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB,EAAA;;AAEpB;EACE,cAAc;EACd,kBAAkB,EAAA;;AAEpB;EACE,gBAAgB,EAAA","sourcesContent":["* {\n  font-family: Roboto, Open Sans, Segoe UI, sans-serif; }\n\n.PaymentFormMainContainer {\n  background-color: white;\n  padding: 2em 2em;\n  width: 100%; }\n\n.field {\n  margin-top: 10px;\n  margin-bottom: 10px;\n  position: relative; }\n\nlabel {\n  display: block;\n  margin-bottom: 8px; }\n\n.cardElement {\n  margin-top: 10px; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PaymentFormMainContainer": "EwalletForm_PaymentFormMainContainer__taNMC",
	"field": "EwalletForm_field__2RZmr",
	"cardElement": "EwalletForm_cardElement__3E-Ks"
};
export default ___CSS_LOADER_EXPORT___;
