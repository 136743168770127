import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {
    clearTransactionError,
    createTransactionAction,
} from "../../actions/actionCreator";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import formStyles from "../../assets/formsStyles.module.sass";
import { withFramePay } from "@rebilly/framepay-react";
import CONSTANTS from "../../constants/constants";
import createRebillyToken from "../../utils/createRebillyToken";
import FormHeader from "../FormHeader/FormHeader";

const PaymentForm = (props) => {
    const { transactionStore, paymentMethod, checkoutToken, Rebilly, billingData, createTransaction } = props;
    const { isFetching } = transactionStore;
    const [errorForm, setErrorForm] = useState(false);

    const validSelectedMethod = paymentMethod.replace(/([\s-]+)/, '_').toUpperCase();
    const content = CONSTANTS.METHODS_CONTENT[`${validSelectedMethod}`];
    let formNode = null;

    const handleError = (err) => {
        toast.error(err.message);
    };

    const onClickedHandler = (values) => {
        values.preventDefault();
        setErrorForm(false);
        try {
            const { firstName, lastName, address1, address2, city, country, region, zip, email, phone } = billingData;
            createRebillyToken({
                Rebilly,
                formNode,
                paymentMethod,
                checkoutToken,
                handleError,
                createTransaction,
                billingAddress: {
                    firstName,
                    lastName,
                    address: address1,
                    address2: address2 || null,
                    city,
                    region,
                    country,
                    postalCode: zip,
                    emails: [{
                        label: 'main',
                        value: email,
                        primary: true
                    }],
                    phoneNumbers: [{
                        label: 'main',
                        value: phone,
                        primary: true
                    }]
                }
            });
        } catch (err) {
            toast.error('Something wrong with payment data, please check fields')
        }
    };

    const setErrorFunc = () => {
        setErrorForm(true);
    };

    useEffect(() => {
        if( !['ok', 'transactionSuccess'].includes(transactionStore?.status) ) {
            setErrorFunc();
        }
    }, [transactionStore]);

    useEffect(() => {
        return () => {
            props.clearTransactionError();
            setErrorForm(false);
        };
    }, [paymentMethod]);

    return (
        <div className={formStyles.PaymentFormMainContainer}>
            <FormHeader isFetching={isFetching} methodTitle={content.methodTitle}/>

            <div className={formStyles.contentWrapper}>
                <div className={formStyles.imgContainer}>
                    <img className={formStyles.methodImage} src={`${CONSTANTS.PAYMENT_LOGOS_PATH}/${validSelectedMethod}.png`} alt={"img"}/>
                </div>
                <label className={formStyles.methodLabel}>{content.methodLabel}</label>
            </div>
            <form ref={node => (formNode = node)} noValidate>
                <fieldset className={formStyles.fieldsetContainer}>
                    <div className={formStyles.fieldError}>
                        {
                            errorForm ? transactionStore?.transactionData?.message : ''
                        }
                    </div>
                </fieldset>

                <div>
                    <button className={formStyles.button} onClick={onClickedHandler}>{content.buttonLabel}</button>
                </div>
            </form>
        </div>
    );
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch) => (
    {
        createTransaction: (data) => dispatch(createTransactionAction(data)),
        clearTransactionError: () => dispatch(clearTransactionError())
    }
);

export default connect(mapStateToProps, mapDispatchToProps)(withFramePay(PaymentForm));