import React from 'react';
import styles from './NavigationBar.module.sass';
import classNames from "classnames";

const NavigationBar = ({viewMode, navButtons: {backToPreviousStep, goToNextStep}}) => {

    const setBreadcrumbStyles = (inputName) => {
        return classNames(styles.breadcrumbItem, {
            [styles.currentStep]: viewMode === inputName
        })
    };

    return (
        <div>
            <nav aria-label="Breadcrumb" className={styles.breadcrumb}>
                <ul>
                    <li><span className={setBreadcrumbStyles("billingForm")} onClick={backToPreviousStep}>Information</span></li>
                    <svg className={styles.svgBreadcrumb} focusable="false" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" width="14px">
                        <path d="M8 1L7 0 3 4 2 5l1 1 4 4 1-1-4-4"/>
                    </svg>
                    <li><span className={setBreadcrumbStyles("paymentForm")} onClick={goToNextStep}>Payment</span></li>
                </ul>
            </nav>
        </div>
    );
};

export default NavigationBar;