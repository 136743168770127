import checkCountry from "./checkCountry";
import GATEWAY_ARRAY from "../constants/gatewaysArray";

const makeAvailableGateways = (prevGatewayArr, customerCountryCode) => {

    if(!!prevGatewayArr) {
        prevGatewayArr.forEach( (arrItem) => {
            const isAvailableCountryForMethod = checkCountry(arrItem.gatewayName, customerCountryCode);

            switch (arrItem.method) {
                case "payment-card":
                    isAvailableCountryForMethod && GATEWAY_ARRAY.set("Card Payment", "payment-card");
                    break;
                case "e-wallet":
                    isAvailableCountryForMethod && GATEWAY_ARRAY.set("E-wallet", "e-wallet");
                    break;
                case "paypal":
                    isAvailableCountryForMethod && GATEWAY_ARRAY.set("PayPal", "paypal");
                    break;
                case "bitcoin":
                    isAvailableCountryForMethod && GATEWAY_ARRAY.set("CoinPayment", "bitcoin");
                    break;
                case "litecoin":
                    isAvailableCountryForMethod && GATEWAY_ARRAY.set("CoinPayment", "litecoin");
                    break;
                case "AstroPay Card":
                    isAvailableCountryForMethod && GATEWAY_ARRAY.set("AstroPay Cards", "AstroPay Card");
                    break;
                case "cash-deposit":
                    isAvailableCountryForMethod && GATEWAY_ARRAY.set("Cash Deposit", "cash-deposit");
                    break;
                case "bank-transfer":
                    isAvailableCountryForMethod && GATEWAY_ARRAY.set("Bank Transfer", "bank-transfer");
                    break;
                default:
                    isAvailableCountryForMethod && GATEWAY_ARRAY.set(arrItem.gatewayName, arrItem.method);
                    break;
            }
        });
    }
    return GATEWAY_ARRAY;
}

export default makeAvailableGateways;