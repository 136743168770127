import { combineReducers } from 'redux';
import checkoutReducer from "./checkoutReducer";
import transactionReducer from "./transactionReducer";
import gatewayAccountsReducer from "./gatewayAccountsReducer";
import getPublicKeyReducer from "./getPublicKeyReducer";

const appReducer = combineReducers({
    checkoutStore: checkoutReducer,
    transactionStore: transactionReducer,
    gatewayAccountsStore: gatewayAccountsReducer,
    publicKeyStore: getPublicKeyReducer
});

export default appReducer;